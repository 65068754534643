/* eslint-disable @typescript-eslint/no-unused-vars */
import { Breadcrumbs, Button, Grid, Link, Typography } from '@mui/material';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import Slacktmp from 'assets/demopng/slack-temp.png';
import Eatmp from 'assets/demopng/ea-temp.png';
import Vktmp from 'assets/demopng/va-temp.png';
import Leftpaginaed from 'assets/png/leftpaginationarrow.svg';
import Rightpaginaed from 'assets/png/rightpaginationarrow.svg';
import { useDebounce } from 'hooks';
import { styled } from '@mui/material/styles';

import _ from 'lodash';
import PositionCard from 'components/Positioncard/PositionCard';
import { useEffect, useState } from 'react';
// import Modalclient from '../Modal';
import { useNavigate } from 'react-router-dom';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import Loader from 'components/loader';
import { getAllListData } from 'services/CommonServices';
import { ClientsENDPOINT, PositionENDPOINT } from 'utils/Constant';
import moment from 'moment';
import Modalposition from '../Modal';
import { useAppSelector } from 'store/hooks';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});

const Defaultparam = {
  skip: 0,
  take: 6,
};

const APPROVEPOSITION = () => {
  const {
    menuList,
    userInfo: { organizationId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [positionData, setPositionData] = useState([]);
  const [positionTotal, setPositionTotal] = useState(0);
  const [selectedClient, setSelectedClient] = useState<string>('');
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const handleOpen = (clientId: string) => {
    setSelectedClient(clientId);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedClient('');
  };

  function handlePag(sk: string) {
    if (sk === 'next') {
      setParams((prev: any) => ({
        ...prev,
        skip: Number(prev.skip) + 6,
      }));
    }
    if (sk !== 'next') {
      setParams((prev: any) => ({
        ...prev,
        skip: Number(prev.skip) - 6,
      }));
    }
  }

  const navigate = useNavigate();

  const getClients = () => {
    const searcharr = [
      `${organizationId ? `organizationId=${organizationId}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
      `${
        debouncedParams.approvalStatus ? `approvalStatus=${debouncedParams.approvalStatus}` : null
      }`,
      'screenType=approve',
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLoader(true);
    getAllListData(
      `${PositionENDPOINT.position}/${PositionENDPOINT.positionApproveList}${
        Object.values({ ...params }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((res) => {
        setPositionData(res && res.data);
        setPositionTotal(res.total);
        setLoader(false);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    getClients();
  }, [debouncedParams]);

  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit' href='' sx={{ cursor: 'pointer' }}>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Positions
            </Link>
            {/* <Link
              underline='hover'
              color='inherit'
              href=''
              onClick={() => navigate(AdminPrivateRoutes.SETTINGS.USERS.USERSLIST)}>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Users
            </Link> */}
            <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Approve Position
            </Typography>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>Approve Position</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <Button
              className='go-to-clnt-list-btn'
              type='submit'
              form={'user-add'}
              onClick={() => navigate(AdminPrivateRoutes.POSITIONS.POSITIONSVIEW)}
              sx={{ textTransform: 'none !important' }}>
              Go to Position List
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box apruv-whitebox'>
            <img
              src={Leftpaginaed}
              alt='left pagination arrow'
              className='leftpaginationscg'
              style={{
                cursor:
                  Number(params.skip) && positionTotal > Number(params.skip) - 6
                    ? 'pointer'
                    : 'not-allowed',
              }}
              onClick={() =>
                Number(params.skip) && positionTotal > Number(params.skip) - 6
                  ? handlePag('inext')
                  : ''
              }
            />

            <img
              src={Rightpaginaed}
              alt='right pagination arrow'
              className='rightpaginationscg'
              style={{
                cursor:
                  Number(params.skip) >= 0 && positionTotal > Number(params.skip) + 6
                    ? 'pointer'
                    : 'not-allowed',
              }}
              onClick={() =>
                Number(params.skip) >= 0 && positionTotal > Number(params.skip) + 6
                  ? handlePag('next')
                  : ''
              }
            />
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  className='client-aprv-grid-container position-apruv-container'>
                  {Array.isArray(positionData) &&
                    positionData.length > 0 &&
                    positionData.map((positionRec: any, i) => {
                      return (
                        <PositionCard
                          key={i}
                          approvalStatus={
                            positionRec.approvalStatus ? positionRec.approvalStatus : ''
                          }
                          profileimg={
                            positionRec.position && positionRec.position.logoId
                              ? positionRec.position.logoId
                              : ''
                          }
                          title={
                            positionRec.position && positionRec.position.title
                              ? positionRec.position.title
                              : ''
                          }
                          created={moment(positionRec.createdAt).format('DD MMM YYYY')}
                          crm={
                            positionRec.position && positionRec.position.crm
                              ? positionRec.position.crm
                              : ''
                          }
                          openings={positionRec.target ? positionRec.target : 0}
                          recuiters={positionRec.recruiterName ? positionRec.recruiterName : ''}
                          uid={positionRec.id}
                          companyname={positionRec.position && positionRec.position.corporateName}
                          onClick={() => handleOpen(positionRec.id)}
                        />
                      );
                    })}
                  <div
                    className='client-card-approve position-apruv-card'
                    style={{ boxShadow: 'none' }}></div>
                  <div
                    className='client-card-approve position-apruv-card'
                    style={{ boxShadow: 'none' }}></div>
                </Typography>
              </Grid>
            </Grid>
          </Typography>
        </Typography>
        {/* {selectedClient && (
          <Modalclient
            clientData={clientData.find((c: any) => c.id === selectedClient)}
            open={open}
            handleClose={handleClose}
            Approved={false}
          />
        )} */}
        <Modalposition
          //  clientData={clientData.find((c: any) => c.id === selectedClient)}
          open={open}
          handleClose={handleClose}
          Approved={false}
          Uid={selectedClient}
        />
      </Grid>
    </Grid>
  );
};

export default APPROVEPOSITION;
