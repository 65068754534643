/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material';
import CloseIcon from 'assets/png/close.svg';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PersonalDetails } from './PersonalDetails';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { resumePersonalSchema } from 'utils/ValidatorSchema';
import Loader from 'components/loader';
import { EducationDetails } from './EducationDetails';
import { ExperienceDetails } from './ExperienceDetails';
import { Consslutant } from './Consslutant';
import { useAppSelector } from 'store/hooks';
import { getSingleData, updateData } from 'services/CommonServices';
import { FILE_ENDPOINT, ResumesENDPOINT } from 'utils/Constant';
import { toast } from 'react-toastify';
import { AccessDenied } from 'utils/helper';
import _ from 'lodash';
import { Skills } from './Skills';
import { ProgressBar } from 'components/CustomProgress';
import CustomPDFViewer from 'components/CustomPDFViewer';
import moment from 'moment';
import { finalCurrency } from 'utils/Config';
// import { alertOptions } from 'utils/Config';

// Custom styled AccordionSummary to match your color and design
const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: '#5b6474', // Blue-gray color
  color: 'white !important', // Ensure text is white
  height: '50px', // Fixed height for both expanded and collapsed state
  minHeight: '50px !important', // Override default min-height
  borderRadius: '5px', // Add border-radius to the AccordionSummary
  marginTop: '5px',
  '&.Mui-expanded': {
    height: '50px', // Ensure height remains 50px when expanded
    minHeight: '50px',
    borderRadius: '5px', // Keep the border-radius in expanded state
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    alignItems: 'center', // Center the content vertically within the fixed height
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'white', // Ensure the expand icon is also white
  },
});

export const EditResume = ({
  addResumeModalOpen,
  setAddResumeModalOpen,
  id,
  fileId,
  // isIgnore,
  listdataapi,
  resumeStatus,
  setResumeRowData,
  view,
}: any) => {
  const {
    // menuList,
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [isExpandedAll, setIsExpandedAll] = useState(true);
  const [loader, setLoader] = useState<any>(false);
  const [removeId, setRemoveId] = useState<any>([]);
  const [removeExperienceId, setRemoveExperienceId] = useState<any>([]);
  const [positionId, setPositionId] = useState<any>();
  const [clientId, setClientId] = useState<any>();
  const [imageUrl, setImageUrl] = useState<any>('');
  const [resumeCompliation, setResumeCompliation] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [jumpIndex, setJumpindex] = React.useState<number>(0);
  const [page, setPage] = React.useState<number>(1);

  const [expand, setExpand] = useState({
    basicSearch: true,
    experience: true,
    education: true,
    additional: true,
    skills: true,
  });
  // Close the modal
  const handleModalClose = () => {
    if (!view) {
      setResumeRowData({});
      setAddResumeModalOpen({ open: false, id: '' });
    } else {
      setAddResumeModalOpen({ open: false, id: '' });
    }
  };

  // Expand all or collapse all accordions
  const expandAll = (value: boolean) => {
    setExpand({
      basicSearch: value,
      experience: value,
      education: value,
      additional: value,
      skills: value,
    });
    setIsExpandedAll(value);
  };

  // Toggle individual accordion panels
  const toggleAccordion = (panel: any) => {
    setExpand((prevState: any) => ({
      ...prevState,
      [panel]: !prevState[panel],
    }));
  };

  const {
    control: control,
    handleSubmit: handleSubmit,
    reset: resetForm,
    setValue,
    setError,
    trigger,
    watch,
    // watch,
    getValues,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      positionFor: '',
      canditateFirstName: '',
      canditateLastName: '',
      canditateEmail: '',
      countryCodeText: '',
      countryCode: '',
      canditatePhoneNo: '',
      formatteValue: '',
      canditateDob: '',
      canditateGender: 'male',
      canditateMaritalStatus: '',
      canditateCurrentDesignation: '',
      canditateTotalExperienceYear: '',
      canditateTotalExperienceMonth: '',
      canditateAnnualCtc: '',
      canditateExpectation: '',
      expectedCtcCurrency: 'inr',
      currentCtcCurrency: 'inr',
      canditateNoticePeriod: '',
      canditateFamily: '',
      // isWorking: false,
      canditatePresentLocation: '',
      canditateNative: '',
      personalId: '',
      id: '',
      passportAndVisa: false,
      passportNumber: '',
      visaNumber: '',
      language: [],
      educationData: [
        {
          rowId: '',
          qualificationId: '',
          marks: '',
          modeOfEducation: '',
          year: '',
          university: '',
          degreeId: '',
          specializationId: '',
          institute: '',
        },
      ],
      experienceData: [
        {
          companyName: '',
          designation: '',
          fromDate: '',
          toDate: '',
          location: '',
          reportingTo: '',
          specialization: '',
          projectAndCertifications: '',
          vitalInformation: '',
          tillDate: false,
          rowId: '',
        },
      ],
      technicalSkills: [],
      behaviouralSkills: [],
      consultantAssessment: '',
      interviewAvailability: '',
      comments: '',
      achievements: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(resumePersonalSchema),
  });
  const {
    fields: fieldsEducation,
    append: appendEducation,
    remove: removeEducation,
    replace: replaceEducation,
  } = useFieldArray({
    control,
    name: 'educationData',
  });
  const {
    fields: fieldsExperience,
    append: appendExperience,
    remove: removeExperience,
    replace: replaceExperience,
  } = useFieldArray({
    control,
    name: 'experienceData',
  });
  const draftData = () => {
    setLoader(true);
    if (id) {
      const education = getValues('educationData').map((re: any) => {
        delete re?.id;
        if (re?.rowId) {
          re['id'] = re.rowId;
        }
        delete re?.rowId;
        if (!re?.modeOfEducation) {
          delete re?.modeOfEducation;
        }
        return re;
      });
      const experience = getValues('experienceData').map((re: any) => {
        delete re.id;
        if (re?.rowId) {
          re['id'] = re.rowId;
        }
        return re;
      });
      const assessmentData = {
        consultantAssessment: getValues('consultantAssessment'),
        interviewAvailability: getValues('interviewAvailability'),
        comments: getValues('comments'),
        achievements: getValues('achievements'),
        technicalSkillData: getValues('technicalSkills'),
        behaviouralSkillData: getValues('behaviouralSkills'),
      };
      const resumeData = {
        isIgnore: true,
        approvalStatus: 'draft',
        personal: {
          id: getValues('personalId'),
          position: getValues('positionFor'),
          firstName: getValues('canditateFirstName'),
          lastName: getValues('canditateLastName'),
          email: getValues('canditateEmail'),
          countryCode: getValues('countryCode'),
          phoneNumber: getValues('canditatePhoneNo').slice(getValues('countryCode').length),
          dob: getValues('canditateDob'),
          designationId: getValues('canditateCurrentDesignation'),
          expYears: getValues('canditateTotalExperienceYear'),
          expMonths: getValues('canditateTotalExperienceMonth'),
          currentCtc: getValues('canditateAnnualCtc')
            ? parseInt(getValues('canditateAnnualCtc').replace(/,/g, ''))
            : 0,
          currentCtcCurrency: getValues('currentCtcCurrency'),
          expectedCtc: getValues('canditateExpectation')
            ? parseInt(getValues('canditateExpectation').replace(/,/g, ''))
            : 0,
          expectedCtcCurrency: getValues('expectedCtcCurrency'),
          noticePeriod: getValues('canditateNoticePeriod'),
          language: watch('language') && watch('language').join(', '),
          // isWorking: getValues('isWorking'),
          gender: getValues('canditateGender'),
          maritalStatus: getValues('canditateMaritalStatus'),
          family: getValues('canditateFamily'),
          presentLocation: getValues('canditatePresentLocation'),
          native: getValues('canditateNative'),
          passportAndVisa: getValues('passportAndVisa') === 'true' ? true : false,
          passportNo: getValues('passportNumber'),
          visaNo: getValues('visaNumber'),
        },
        educations: education,
        experiences: experience,
        assessment: assessmentData,
        removeEducationIds: removeId,
        removeExperienceIds: removeExperienceId,
      };

      updateData(id, resumeData, `${ResumesENDPOINT.resumeList}`, true)
        .then(() => {
          handleModalClose();
          listdataapi();

          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
          console.log(e);
        });
    }
  };
  const onSubmitForm = (data: any) => {
    if (id) {
      if (
        Number(data.canditateTotalExperienceYear) != 0 &&
        data?.experienceData.length > 0 &&
        !data?.experienceData[0]?.reportingTo
      ) {
        setError(`experienceData.${0}.reportingTo`, {
          type: 'manual',
          message: 'Reporting To is required',
        });
        return;
      }
      setLoader(true);
      const education = data.educationData.map((re: any) => {
        delete re?.id;
        if (re?.rowId) {
          re['id'] = re.rowId;
        }
        delete re?.rowId;
        if (!re?.modeOfEducation) {
          delete re?.modeOfEducation;
        }
        return re;
      });
      const experience = data.experienceData.map((re: any) => {
        delete re.id;
        if (re?.rowId) {
          re['id'] = re.rowId;
        }
        re.fromDate = moment(re.fromDate).format('YYYY-MM-DD');
        re.toDate = moment(re.toDate).format('YYYY-MM-DD');

        return re;
      });
      const assessmentData = {
        consultantAssessment: data.consultantAssessment,
        interviewAvailability: data.interviewAvailability,
        comments: data.comments,
        achievements: data.achievements,
        technicalSkillData: data.technicalSkills,
        behaviouralSkillData: data.behaviouralSkills,
      };
      const resumeData = {
        isIgnore: true,
        approvalStatus: 'inReview',
        personal: {
          id: data.personalId,
          position: data.positionFor,
          firstName: data.canditateFirstName,
          lastName: data.canditateLastName,
          email: data.canditateEmail,
          countryCode: data.countryCode,
          phoneNumber: data.canditatePhoneNo.slice(data.countryCode.length),
          dob: data.canditateDob,
          designationId: data.canditateCurrentDesignation,
          expYears: Number(data.canditateTotalExperienceYear),
          expMonths: Number(data.canditateTotalExperienceMonth),
          currentCtc: data.canditateAnnualCtc
            ? parseInt(String(data.canditateAnnualCtc).replace(/,/g, ''))
            : 0,
          currentCtcCurrency: data.currentCtcCurrency,
          expectedCtc: data.canditateExpectation
            ? parseInt(String(data.canditateExpectation).replace(/,/g, ''))
            : 0,
          expectedCtcCurrency: data.expectedCtcCurrency,
          noticePeriod: data.canditateNoticePeriod,
          // isWorking: data.isWorking === 'true' ? true : false,
          gender: data.canditateGender,
          maritalStatus: data.canditateMaritalStatus,
          family: data.canditateFamily,
          presentLocation: data.canditatePresentLocation,
          native: data.canditateNative,
          passportAndVisa: data.passportAndVisa,
          passportNo: data.passportNumber,
          visaNo: data.visaNumber,
          language: data.language && data.language.join(', '),
        },
        educations: education,
        experiences: experience,
        assessment: assessmentData,
        removeEducationIds: removeId,
        removeExperienceIds: removeExperienceId,
      };

      updateData(id, resumeData, `${ResumesENDPOINT.resumeList}`, true)
        .then(() => {
          handleModalClose();
          setResumeRowData({});
          listdataapi();
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
          console.log(e);
        });
    }
  };
  React.useEffect(() => {
    if (fileId) {
      setLoader(true);
      getSingleData('', `${FILE_ENDPOINT.files}/resume/${fileId}`, true, { responseType: 'blob' })
        .then((fileRes: any) => {
          setImageUrl(URL?.createObjectURL(fileRes));
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
          console.log(e);
        });
    }
  }, [fileId]);

  const getInitialStatus = async () => {
    if (id) {
      setLoader(true);
      getSingleData(id, ResumesENDPOINT.resumeList)
        .then(async (resp: any) => {
          if (resp && !_.isEmpty(resp.data)) {
            // setResumeFileId(resp?.data[0]?.fileId);
            // setValue('id', resp?.data[0]?.personal?.id);
            const dat: any = resp?.data[0]?.education;
            const finalData: any = [];
            dat && dat.length > 0
              ? dat?.map((ses: any) => {
                  finalData.push({
                    qualificationId: ses.qualificationId,
                    marks: ses.marks,
                    modeOfEducation: ses.modeOfEducation,
                    year: ses.year,
                    university: ses.university,
                    degreeId: ses.degreeId,
                    specializationId: ses.specializationId,
                    institute: ses.institute,
                    rowId: ses.id,
                  });
                })
              : finalData.push({
                  qualificationId: '',
                  marks: '',
                  modeOfEducation: '',
                  year: '',
                  university: '',
                  degreeId: '',
                  specializationId: '',
                  institute: '',
                  rowId: '',
                });

            const data: any = resp?.data[0]?.experience;
            const finalDatas: any = [];
            data && data.length > 0
              ? data.map((se: any) => {
                  finalDatas.push({
                    companyName: se.companyName,
                    designation: se.designation,
                    fromDate: se.fromDate,
                    toDate: se.toDate,
                    location: se.location,
                    reportingTo: se.reportingTo,
                    specialization: se.specialization,
                    projectAndCertifications: se.projectAndCertifications,
                    vitalInformation: se.vitalInformation,
                    tillDate: se.tillDate,
                    rowId: se.id,
                  });
                })
              : resp?.data[0]?.personal?.expYears != 0 &&
                finalDatas.push({
                  companyName: '',
                  designation: '',
                  fromDate: '',
                  toDate: '',
                  location: '',
                  reportingTo: '',
                  specialization: '',
                  projectAndCertifications: '',
                  vitalInformation: '',
                  tillDate: false,
                  rowId: '',
                });

            resetForm({
              experienceData: finalDatas,
              educationData: finalData,
            });

            resumeData(resp?.data && resp?.data[0]);
            setValue('clientId', resp?.data[0]?.client?.id);
            setClientId(resp?.data[0]?.client?.id);
            setValue('positionId', resp?.data[0]?.position.id);
            setValue('id', resp?.data[0]?.id);
            setValue('personalId', resp?.data[0]?.personal?.id);
            setPositionId(resp?.data[0]?.position?.id);
            setValue('positionFor', resp?.data[0]?.position && resp?.data[0]?.position?.title);
            setValue('canditateFirstName', resp?.data[0]?.personal?.firstName);
            setValue('canditateLastName', resp?.data[0]?.personal?.lastName);
            setValue('canditateEmail', resp?.data[0]?.personal?.email);
            setValue(
              'canditatePhoneNo',
              `${resp?.data[0]?.personal?.countryCode}${resp?.data[0]?.personal?.phoneNumber}`,
            );
            setValue(
              'formatteValue',
              `+${resp?.data[0]?.personal?.countryCode} ${resp?.data[0]?.personal?.phoneNumber}`,
            );
            setValue('canditateDob', resp?.data[0]?.personal?.dob);
            setValue('canditateGender', resp?.data[0]?.personal?.gender);
            setValue('canditateMaritalStatus', resp?.data[0]?.personal?.maritalStatus);
            setValue('canditateCurrentDesignation', resp?.data[0]?.personal?.designationId);
            setValue('canditateTotalExperienceYear', String(resp?.data[0]?.personal?.expYears));
            setValue('canditateTotalExperienceMonth', String(resp?.data[0]?.personal?.expMonths));

            setValue(
              'canditateAnnualCtc',
              resp?.data[0]?.personal?.currentCtc
                ? finalCurrency(
                    resp?.data[0]?.personal?.currentCtc,
                    resp?.data[0]?.personal?.currentCtcCurrency.toLocaleUpperCase(),
                  )
                : '',
            );
            setValue(
              'canditateExpectation',
              resp?.data[0]?.personal?.expectedCtc
                ? finalCurrency(
                    resp?.data[0]?.personal?.expectedCtc,
                    resp?.data[0]?.personal?.expectedCtcCurrency.toLocaleUpperCase(),
                  )
                : '',
            );
            setValue('currentCtcCurrency', resp?.data[0]?.personal?.currentCtcCurrency);
            setValue('expectedCtcCurrency', resp?.data[0]?.personal?.expectedCtcCurrency);
            setValue('canditateFamily', resp?.data[0]?.personal?.family);
            // setValue('isWorking', resp?.data[0]?.personal?.isWorking);
            setValue('canditatePresentLocation', resp?.data[0]?.personal?.presentLocation);
            setValue('canditateNative', resp?.data[0]?.personal?.native);
            setValue('canditateNoticePeriod', resp?.data[0]?.personal?.noticePeriod);
            setValue('countryCode', resp?.data[0]?.personal?.countryCode);
            setValue(
              'passportAndVisa',
              resp?.data[0]?.personal?.passportAndVisa ? 'true' : 'false',
            );
            setValue('passportNumber', resp?.data[0]?.personal?.passportNo);
            resp?.data[0]?.personal?.language &&
              [resp?.data[0]?.personal?.language.split(', ')].map((re: any) =>
                setValue('language', re),
              );
            setValue('visaNumber', resp?.data[0]?.personal?.visaNo);
            const dates: any = resp.data[0]?.personal;
            setValue('consultantAssessment', dates.consultantAssessment);
            setValue('achievements', dates.achievements);
            setValue('comments', dates.comments);
            setValue('isSaved', resp?.data[0]?.assessment[0]?.isSaved);
            setValue('interviewAvailability', dates.interviewAvailability);
            const behaviouralSkills = resp?.data[0]?.assessment
              .filter((re: any) => re.behaviouralSkillsId)
              .map((re: any, index: number) => {
                if (re.behaviouralSkillsId) {
                  setValue(
                    `behaviouralSkills.${index}.behaviouralSkillsId`,
                    re.behaviouralSkillsId,
                  );
                  setValue(`behaviouralSkills.${index}.rating`, re.rating);
                  setValue(`behaviouralSkills.${index}.id`, re.id);
                }
                return;
              });
            await Promise.all(behaviouralSkills);
            const technicalSkills = resp?.data[0]?.assessment
              .filter((re: any) => re.technicalSkillsId)
              .map((re: any, index: number) => {
                if (re.technicalSkillsId) {
                  setValue(`technicalSkills.${index}.technicalSkillsId`, re.technicalSkillsId);
                  setValue(`technicalSkills.${index}.rating`, re.rating);
                  setValue(`technicalSkills.${index}.id`, re.id);
                }
                return;
              });
            await Promise.all(technicalSkills);
          }
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            // navigate('/dashboard');
          }
        });
    }
  };
  const resumeData = (data: any) => {
    setResumeCompliation(
      (data?.personal?.firstName ? 1.7 : 0) +
        (data?.personal?.lastName ? 1.7 : 0) +
        (data?.personal?.email ? 1.7 : 0) +
        (data?.personal?.phoneNumber ? 1.7 : 0) +
        (data?.personal?.dob ? 1.7 : 0) +
        (data?.personal?.designationId ? 1.7 : 0) +
        (String(data?.personal?.expYears) ? 1.7 : 0) +
        (data?.personal?.expYears === 0
          ? 6.8
          : (String(data?.personal?.currentCtc) ? 1.7 : 0) +
            (String(data?.personal?.expectedCtc) ? 1.7 : 0) +
            (String(data?.personal?.expMonths) ? 1.7 : 0) +
            (String(data?.personal?.noticePeriod) ? 1.7 : 0)) +
        (data?.personal?.gender ? 1.7 : 0) +
        (String(data?.personal?.passportAndVisa) ? 1.7 : 0) +
        (data?.personal?.presentLocation ? 2.9 : 0) +
        (data?.education[0]?.qualificationId ? 8.3 : 0) +
        (data?.education[0]?.year ? 8.3 : 0) +
        (data?.education[0]?.institute ? 8.4 : 0) +
        (data?.personal?.expYears === 0
          ? 25
          : (data?.experience[0]?.companyName ? 3.5 : 0) +
            (data?.experience[0]?.designation ? 3.5 : 0) +
            (data?.experience[0]?.fromDate ? 3.5 : 0) +
            (data?.experience[0]?.tillDate ? 3.5 : data?.experience[0]?.toDate ? 3.5 : 0) +
            (data?.experience[0]?.specialization ? 3.5 : 0) +
            (data?.experience[0]?.location ? 3.5 : 0) +
            (data?.experience[0]?.reportingTo ? 4 : 0)) +
        (data?.personal?.achievements ? 8.3 : 0) +
        (data?.personal?.interviewAvailability ? 8.3 : 0) +
        (data?.personal?.consultantAssessment ? 8.4 : 0),
    );
  };
  React.useEffect(() => {
    getInitialStatus();
  }, []);
  const updateSearchPage = React.useCallback(
    (pageNumber: React.SetStateAction<number>) => {
      setPage(pageNumber);
    },
    [page],
  );
  return (
    <Modal sx={{ zIndex: 1300 }} open={addResumeModalOpen} onClose={() => false}>
      <Box
        className='email-modal pdf-viewer'
        sx={{ width: '95%', height: '90vh', display: 'flex', padding: '0px', overflow: 'hidden' }}>
        {loader && <Loader />}
        {/* PDF viewer */}
        <Typography sx={{ width: '50%' }}>
          {imageUrl ? (
            <CustomPDFViewer
              updateSearchPage={updateSearchPage}
              fileUrl={imageUrl}
              jumpIndex={jumpIndex}
            />
          ) : (
            // <iframe
            //   style={{ height: '100vh', width: '100%', border: 'none' }}
            //   src={`data:application/pdf;base64,${imageUrl}`}
            // />
            <div
              style={{
                position: 'relative',
                top: '45%',
                left: 0,
                right: 0,
                bottom: 0,
                background: ' rgba(255, 255, 255, 0.8)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              Loading...
            </div>
          )}
        </Typography>

        {/* Divider */}
        <Typography sx={{ background: 'gray', height: '100vh', width: '10px' }} />

        {/* Form content with accordions */}
        <Typography sx={{ height: '83vh', width: '50%', padding: 2 }}>
          {/* Modal header */}
          <Typography className='d-flex-a flex-sb ml-10' style={{ height: '40px' }}>
            <Typography className='l-title'>Add Resume</Typography>
            <Typography component={'div'} className='d-flex'>
              {resumeCompliation > 0 && (
                <Typography
                  component={'div'}
                  style={{
                    marginTop: '18px',
                    marginRight: '15px',
                  }}>
                  <ProgressBar
                    percentage={resumeCompliation}
                    startColor='#3B82F6'
                    midColor='#A855F7'
                    endColor='#EC4899'
                    gradientId='progress'>
                    <div
                      style={{
                        fontSize: '12px',
                        color: '#344051',
                        fontWeight: '500',
                        position: 'relative',
                        textAlign: 'center',
                        marginTop: '50px',
                      }}>
                      {resumeCompliation.toFixed()}%
                    </div>
                  </ProgressBar>
                  <Typography
                    component={'div'}
                    style={{
                      fontSize: '12px',
                      color: '#344051',
                      fontWeight: '100',
                      position: 'relative',
                      textAlign: 'center',
                      marginTop: '18px',
                    }}>
                    Resume Completion
                  </Typography>
                </Typography>
              )}
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleModalClose}
              />
            </Typography>
          </Typography>
          <Typography className='bdiveder' />
          <Typography
            component={'div'}
            sx={{
              maxHeight: '70vh',
              overflowY: 'auto',
              // scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              padding: '0px 20px 0px 20px',
            }}>
            {/* Collapse All button */}
            {expand.basicSearch &&
            expand.experience &&
            expand.education &&
            expand.additional &&
            expand.skills ? (
              <Typography
                className='d-flex flex-end'
                sx={{ color: '#5F2EE5', cursor: 'pointer', fontSize: '14px' }}
                onClick={() => expandAll(!isExpandedAll)}>
                Collapse All
              </Typography>
            ) : (
              <Typography
                className='d-flex flex-end'
                sx={{ color: '#5F2EE5', cursor: 'pointer', fontSize: '14px' }}
                onClick={() => expandAll(true)}>
                Expand All
              </Typography>
            )}

            {/* Accordions */}
            <Grid container className='mt-10'>
              <Grid item xs={12}>
                <form
                  className='private-form'
                  id={'personal-form'}
                  onSubmit={handleSubmit(onSubmitForm)}>
                  {/* Personal Section */}
                  <Accordion
                    sx={{
                      '&.MuiAccordion-rounded': {
                        boxShadow: 'none !important',
                      },
                    }}
                    expanded={expand.basicSearch}
                    onChange={() => toggleAccordion('basicSearch')}>
                    <StyledAccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                      aria-controls='panel1-content'
                      id='panel1-header'>
                      <Typography className='f-family'>Personal Details</Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails sx={{ padding: '0px !important' }}>
                      <PersonalDetails
                        control={control}
                        append={appendExperience}
                        handleSubmit={handleSubmit}
                        reset={resetForm}
                        setValue={setValue}
                        setError={setError}
                        watch={watch}
                        getValues={getValues}
                        errors={errors}
                        trigger={trigger}
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* Education Section */}
                  <Accordion
                    sx={{
                      '&.MuiAccordion-rounded': {
                        boxShadow: 'none !important',
                      },
                    }}
                    expanded={expand.education}
                    onChange={() => toggleAccordion('education')}>
                    <StyledAccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                      aria-controls='panel3-content'
                      id='panel3-header'>
                      <Typography className='f-family'>Education Details</Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails sx={{ padding: '0px !important' }}>
                      <EducationDetails
                        fields={fieldsEducation}
                        append={appendEducation}
                        remove={removeEducation}
                        replace={replaceEducation}
                        control={control}
                        reset={resetForm}
                        setValue={setValue}
                        setError={setError}
                        watch={watch}
                        getValues={getValues}
                        errors={errors}
                        trigger={trigger}
                        setRemoveId={setRemoveId}
                        removeId={removeId}
                      />
                    </AccordionDetails>
                  </Accordion>

                  {/* Experience Section */}
                  {watch('canditateTotalExperienceYear') != 0 && (
                    <Accordion
                      sx={{
                        '&.MuiAccordion-rounded': {
                          boxShadow: 'none !important',
                        },
                      }}
                      expanded={expand.experience}
                      onChange={() => toggleAccordion('experience')}>
                      <StyledAccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                        aria-controls='panel2-content'
                        id='panel2-header'>
                        <Typography className='f-family'>Experience Details</Typography>
                      </StyledAccordionSummary>
                      <AccordionDetails sx={{ padding: '0px !important' }}>
                        <ExperienceDetails
                          fields={fieldsExperience}
                          append={appendExperience}
                          remove={removeExperience}
                          replace={replaceExperience}
                          control={control}
                          reset={resetForm}
                          setValue={setValue}
                          setError={setError}
                          watch={watch}
                          getValues={getValues}
                          errors={errors}
                          trigger={trigger}
                          setRemoveId={setRemoveExperienceId}
                          removeId={removeExperienceId}
                        />
                      </AccordionDetails>
                    </Accordion>
                  )}
                  {/* Additional Section */}
                  <Accordion
                    sx={{
                      '&.MuiAccordion-rounded': {
                        boxShadow: 'none !important',
                      },
                    }}
                    expanded={expand.additional}
                    onChange={() => toggleAccordion('additional')}>
                    <StyledAccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                      aria-controls='panel4-content'
                      id='panel4-header'>
                      <Typography className='f-family'>Consultant Assessment Details</Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails sx={{ padding: '0px !important' }}>
                      <Consslutant
                        control={control}
                        reset={resetForm}
                        setValue={setValue}
                        setError={setError}
                        watch={watch}
                        getValues={getValues}
                        errors={errors}
                        trigger={trigger}
                        positionId={positionId}
                        clientId={clientId}
                        orgId={orgdy}
                      />
                    </AccordionDetails>
                  </Accordion>
                  {/*  Skills*/}
                  <Accordion
                    sx={{
                      '&.MuiAccordion-rounded': {
                        boxShadow: 'none !important',
                      },
                    }}
                    expanded={expand.skills}
                    onChange={() => toggleAccordion('skills')}>
                    <StyledAccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                      aria-controls='panel4-content'
                      id='panel4-header'>
                      <Typography className='f-family'>Skills</Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails sx={{ padding: '0px !important' }}>
                      <Box className='skills-Box'>
                        <Skills resumeId={id} />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </form>
              </Grid>
            </Grid>
            <Typography
              className='d-flex-a'
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '10px 20px',
                backgroundColor: '#fff',
                boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
                zIndex: 10,
                width: '49.5%',
                marginLeft: ' 50.5%',
              }}>
              {['readyForReview', 'draft', 'inProcess'].includes(resumeStatus.approvalStatus) ? (
                <Button
                  className='cancel-btn mr-16'
                  onClick={draftData}
                  style={{
                    border: '1px solid #EE4B22',
                    color: '#EE4B22',
                  }}>
                  Draft
                </Button>
              ) : (
                ''
              )}
              <Button className='s-add-btn' form={'personal-form'} type={'submit'}>
                Save
              </Button>
            </Typography>
          </Typography>
        </Typography>
      </Box>
    </Modal>
  );
};
