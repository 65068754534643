export const MASTER_ENDPOINT = {
  Login: 'auth/login',
  Logout: 'auth/logout',
  verifyOtp: 'auth/verify-otp',
  generateOtp: 'auth/generate-otp',
};
export const ClientsENDPOINT = {
  Client: 'client',
  CilentPercentage: 'client/percentage',
  ClientLevel: 'client/level',
  ClientCTC: 'client/ctc',
  ClientDays: 'client/days',
  ClientProposalVersion: 'client/proposal-version',
};

export const PositionENDPOINT = {
  position: 'position',
  positionList: 'list',
  CRMChange: 'position/crm',
  totalApproval:'total-approval',
  totalCRMApproval:'total-crm-approval',
  positionApproveList: 'approve/list',
  lite: 'lite',
  view: 'view',
};
export const PlanENDPOINT = {
  plan: 'plan',
};
export const BillingENDPOINT = {
  billing: 'billing',
  PaymentMail: 'billing/payment-mail',
  billingPayment: 'billing/payment',
  billingInvoice: 'billing/invoice',
  cancelInvoice: 'billing/invoice/cancel',
  clientAgreement: 'billing/client-agreement',
};
export const CVBANKENDPOINT = {
  cvBank: 'cv-bank',
};
export const ResumesENDPOINT = {
  resumeListData: 'resumes/list-screen',
  resumeSkills: 'resumes/skills',
  resumeList: 'resumes',
  resumeUpload: 'resumes/upload',
  resumeSnapshot: 'resumes/snapshot',
  download: 'resumes/download',
  cvUpload: 'email-template/send-cv',
  sendCV: 'resumes/send-cv',
  emailTempalate: 'email-template/interview',
  interview: 'interview',
  OverAllStatus: 'resumes/overall-status',
  task: 'resumes/task',
  resumeDuplicate: 'resumes/duplicate',
};

export const InterviewENDPOINT = {
  interviewList: 'interview',
};
export const MailboxENDPOINT = {
  mailboxList: 'mail-box',
};
export const VERSIONHISTORYENDPOINT = {
  versionHistory: 'version-history',
};
export const REPORT_ENDPOINT = {
  openingHandled: 'reports/opening-handled',
  cvStatus: 'reports/cv-status',
  taktTime: 'reports/takt-time',
  productivity: 'reports/productivity',
  businessConversion: 'reports/business-conversion',
  employee: 'reports/users',
  branch: 'reports/branch',
};
export const SETTING_ENDPOINT = {
  Branch: 'branch',
  Qualification: 'qualification',
  Qualificationdetails: 'qualification/list',
  Degreedetails: 'qualification/degree',
  EmailTemplate: 'email-template',
  functionalArea: 'functional-area',
  designation: 'designation',
  users: 'users',
  roles: 'roles',
  dailylogindetails: 'activity',
  userFunctionalRoles: 'roles/user-functional-roles',
  moduleScreenList: 'roles/modules',
  rolesPermission: 'roles/permission',
  leaves: {
    holidays: 'holiday',
    importLeave: 'import-leave',
    leavePolicy: 'leave-policy',
  },
  leavePolicy: {
    policylist: 'leave-policy/type',
    leavePolicyList: 'leave-policy',
  },
  Industry: 'industry',
  OverAllLeaves: 'holiday/leave-days',
  CtcSlab: 'ctc-slab',
};
export const EVENTS_ENDPOINT = {
  event: 'event',
};
export const PROFILE_ENDPOINT = {
  profile: 'users',
};

export const FILE_ENDPOINT = {
  files: 'files',
};

export const FEEDBACK_ENDPOINT = {
  reportBug: 'reportbug-and-feedback',
};

export const LEAVE_ENDPOINT = {
  policyTypeGet: 'leave-policy/type',
  leaves: 'leaves',
};
export const DASHBOARD_ENDPOINT = {
  Category: 'dashboard/category',
  CalculationTable: 'dashboard/calculation-table',
  Clients: 'dashboard/clients',
  Position: 'dashboard/positions',
  Offers: 'dashboard/offers',
  event: 'dashboard/event',
  Productivity: 'dashboard/productivity',
  BusinessConversion: 'dashboard/business-conversion',
};
