/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Breadcrumbs,
  Button,
  ClickAwayListener,
  // Button,
  // Checkbox,
  // FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
// import EditIcon from 'assets/png/edit.svg';
// import DeleteIcon from 'assets/png/delete.svg';
// import AddIcon from 'assets/png/addplus.svg';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { To, useNavigate } from 'react-router-dom';
import { deleteData, getAllListData, updateData } from 'services/CommonServices';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { alertOptions } from 'utils/Config';
import { toast } from 'react-toastify';
import { useDebounce } from 'hooks';
import { FILE_ENDPOINT, SETTING_ENDPOINT } from 'utils/Constant';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import moment from 'moment';
import PreIcon from 'assets/png/pre.svg';
import Paginate from 'components/Pagination/Pagination';
import LaptopIcon from './laptopsvg';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import ImageWithAuth from 'components/ProfileImage';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { AccessDenied } from 'utils/helper';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  // boxShadow:
  //   theme.palette.mode === 'dark'
  //     ? '0 0 0 1px rgb(16 22 26 / 40%)'
  //     : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#EFEFEF' : '#EFEFEF',
  // backgroundImage:
  //   theme.palette.mode === 'dark'
  //     ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
  //     : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '1px auto #D0D5DD',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#0061FF',
  // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#0061FF',
  },
});

function createData(
  name?: string,
  calories?: number,
  fat?: number,
  carbs?: number,
  protein?: number,
) {
  return { name, calories, fat, carbs, protein };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rows = [
  createData('Frozen yoghurt'),
  createData('Ice cream sandwich'),
  createData('Eclair'),
  createData('Cupcake'),
  createData('Gingerbread'),
];

const Defaultparam = {
  search: null,
  order: null,
  skip: 0,
  sortby: null,
  status: null,
  take: 50,
  address: null,
  pincode: null,
  type: null,
};

const Iptogler = ({
  btst,
  Ipid,
  setLd,
  listdataapi,
}: {
  btst: boolean;
  Ipid: any;
  setLd?: any;
  listdataapi: any;
}) => {
  const [clik, setclik] = useState(false);
  const [blstat, setblstat] = useState('');
  useLayoutEffect(() => {
    setclik(btst);
  }, [btst]);
  const handleBlovkip = async (msg: boolean, act: 'bl' | 'nbl') => {
    //  console.log(msg);
    try {
      setLd(true);
      setclik(msg);
      setblstat(act);
      await updateData(
        Ipid,
        {
          blocked: msg && act === 'bl' ? true : false,
        },
        'activity/access',
        true,
      );
      listdataapi();
    } catch (err) {
      toast.error(String(err), alertOptions);
      setclik(!msg);
      setblstat('');
    } finally {
      setLd(!true);
    }
  };
  return (
    <TableCell
      className={clik ? 'dip-button' : 'ip-button'}
      onMouseOver={() => setclik((p) => !p)}
      onMouseOut={() => {
        if (!blstat) {
          setclik((p) => !p);
          return;
        }
        blstat === 'bl' ? setclik(true) : setclik(!true);
      }}>
      {clik ? (
        <span onClick={() => handleBlovkip(true, 'bl')}>Block IP</span>
      ) : (
        <span onClick={() => handleBlovkip(!true, 'nbl')}>Active</span>
      )}
    </TableCell>
  );
};

const HistoryPop = ({ ListActivity, stats }: { ListActivity?: any; stats?: boolean }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const historypopup = {
    borderRadius: '6px',
    background: '#fff',
    boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.25)',
    minWidth: '300px',
    maxWidth: '600px',
  };
  const histopocon = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    borderBottom: '2px solid #F2F2F2',
    paddingBottom: '16px',
  };
  const histime = {
    color: '#344051',
    // cursor: 'pointer',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px' /* 114.286% */,
  };
  const hisurl = {
    // color: '#344051',
    color: '#141C24',
    // cursor: 'pointer',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px',
  };

  return (
    <>
      <PopupState variant='popover' popupId='demo-popup-popover'>
        {(popupState) => (
          <div>
            <Typography
              component={'div'}
              sx={{ transform: 'translateX(-16px)' }}
              {...bindTrigger(popupState)}>
              <LaptopIcon opn={handleTooltipOpen} stats={stats ? stats : false} />
            </Typography>

            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}>
              <Box className='historypopup' sx={historypopup}>
                <div style={{ height: '22px' }}></div>
                <Box
                  sx={{
                    maxHeight: '200px',
                    overflow: 'auto',
                    padding: '0px 13px',
                    paddingRight: '22px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '14px',
                  }}>
                  {ListActivity && ListActivity.length > 0 ? (
                    ListActivity.map((re: any, idk: any) => {
                      return (
                        <Typography className='histypocon' sx={histopocon} key={idk}>
                          <Typography className='histime' sx={histime}>
                            {re && re.createdAt
                              ? moment(re.createdAt).format('YYYY MMM Do | hh:mm:ss')
                              : ' '}
                          </Typography>
                          <Typography className='hisurl' sx={hisurl}>
                            {re && re.pageUrl ? re.pageUrl : ' '}
                          </Typography>
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography
                      className='histypocon'
                      sx={{
                        ...histopocon,
                        borderBottom: 'none',
                        paddingBottom: '4px',
                        justifyContent: 'center',
                        flexDirection: 'row',
                      }}>
                      <Typography className='histime' sx={histime}>
                        No Records Found
                      </Typography>
                      <Typography className='hisurl' sx={hisurl}></Typography>
                    </Typography>
                  )}
                </Box>
                <div style={{ height: '18px' }}></div>
              </Box>
            </Popover>
          </div>
        )}
      </PopupState>
    </>
  );
};

const DailyLoginDetails = () => {
  const containerRef = useRef(null) as any;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  // const [orgdy,setorgdy] = useState('');
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();
  const [commonstate, setcommonstate] = useState<any[]>([]);
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const [Ld, setLd] = useState(false);
  const debouncedParams = useDebounce(params, 500);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  }

  const listdataapi = () => {
    // const searcharr = [`${orgdy ? `id=${orgdy}`:null}`,`${debouncedParams.search ? `search=${debouncedParams.search}`:null}`,`${debouncedParams.status ? `status=${debouncedParams.status}`:null}`,`${debouncedParams.order ? `order=${debouncedParams.order}`:null}`,`${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}`:null}`,`${debouncedParams.skip && debouncedParams.take ? `skip=${debouncedParams.skip?debouncedParams.skip:0}`:debouncedParams.take?`skip=0`:null}`,`${debouncedParams.take ? `take=${debouncedParams.take}`:null}`].filter((ea:any) => ea !== 'null').join('&');
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.status ? `status=${debouncedParams.status}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);
    getAllListData(
      `${SETTING_ENDPOINT.dailylogindetails}${
        Object.values({ ...params, organizationId: orgdy }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setcommonstate(val.data);
          setpagetotal(Number(val.total));
        } else {
          setcommonstate([]);
        }
        setLd(!true);
      })
      .catch((e) => {
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(!true);
      });
  };

  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);

  const deletebtn = async (id: any) => {
    setLd(true);
    try {
      const deleted = await deleteData(id, `${SETTING_ENDPOINT.functionalArea}`, true);
      toast.success(deleted.message, alertOptions);
      setConfirmOpen({
        show: false,
        id: '',
      });
      listdataapi();
    } catch (err) {
      toast.error(String(err), alertOptions);
    } finally {
      setLd(!true);
    }
  };
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };
  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };

  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Link underline='hover' color='inherit'>
                <img src={ArrowIcon} alt='arrow' className='arrows' />
                Settings
              </Link>
              <Typography color='text.primary'>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Daily Login Details
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
            <Typography className='page-title'>Daily Login Details</Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <TextField
                className='custom-search'
                placeholder='Search'
                id='outlined-start-adornment'
                value={params.search}
                sx={{ width: '320px' }}
                onChange={(e: any) =>
                  setParams((prev: any) => ({
                    ...prev,
                    search: e.target.value,
                    skip: 0,
                    take: 50,
                  }))
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <img src={SearchIcon} alt='search' />
                    </InputAdornment>
                  ),
                  endAdornment: params.search ? (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        edge='end'
                        onClick={() => {
                          setParams(() => ({ ...Defaultparam, search: '' }));
                        }}>
                        <CloseIcon style={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
              />
              <Typography component={'div'} className='mt-32' sx={{ position: 'relative' }}>
                <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                  <img src={PreIcon} alt='' />
                </Button>
                <Button onClick={() => handleScroll(200)} className='next-btn'>
                  <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
                </Button>
                <TableContainer component={Paper} className='sb-table' ref={containerRef}>
                  <Table
                    sx={{ minWidth: '1200px' }}
                    className='custom-table'
                    aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='center' className='w250'>
                          Username
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('name', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('name', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell>
                          IP Address
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('userIpAddress', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('userIpAddress', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell>
                          Date / Time
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell>
                          Browser
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('browser', 'desc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('browser', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell>
                          Device / Location
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('device', 'desc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('device', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell>View History</TableCell>
                        <TableCell>Blacklist IP</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commonstate && commonstate?.length > 0 ? (
                        commonstate?.map((row) => (
                          <TableRow
                            key={row?.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell>
                              <div
                                style={{
                                  minWidth: '150px',
                                  textTransform: 'capitalize',
                                  display: 'flex',
                                  gap: '16px',
                                  alignItems: 'center',
                                }}>
                                {row &&
                                row?.user &&
                                row?.user?.profileImage &&
                                row?.user?.imageId &&
                                row?.user?.imageId?.length > 0 ? (
                                  <Typography className='ip-img'>
                                    <ImageWithAuth
                                      className='default-ippic'
                                      id={row?.user?.imageId}
                                    />
                                  </Typography>
                                ) : (
                                  <Typography className='ip-img'>
                                    <Typography className='default-iptext'>
                                      {row?.user?.firstName?.toUpperCase().trim().split('')[0] +
                                        row?.user?.lastName?.toUpperCase().trim().split('')[0]}
                                    </Typography>
                                  </Typography>
                                )}
                                <TableTooltip
                                  value={
                                    row && row?.user
                                      ? `${row?.user?.firstName ? row?.user?.firstName : ''} ${
                                          row?.user?.lastName ? row?.user?.lastName : ''
                                        }`
                                      : ' '
                                  }></TableTooltip>
                              </div>
                            </TableCell>
                            <TableCell>
                              {row && row?.ip && row?.ip?.ip ? row?.ip?.ip : ''}
                            </TableCell>
                            <TableCell>
                              {row && row?.createdAt
                                ? moment(row?.createdAt).format('Do MMM YYYY | hh:mm:ss A')
                                : ' '}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize' }}>
                              {row && row?.browser ? row?.browser : ' '}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'upperCase' }}>
                              {row
                                ? `${row?.device ? row?.device : ''} / ${
                                    row?.location ? row?.location : ''
                                  }`
                                : ' '}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              <HistoryPop
                                ListActivity={
                                  row && row?.userActivity && row?.userActivity?.length > 0
                                    ? row?.userActivity
                                    : ''
                                }
                                stats={
                                  row && row?.userActivity && row?.userActivity?.length > 0
                                    ? false
                                    : true
                                }
                              />
                            </TableCell>
                            <TableCell className={'ip-togler'}>
                              <Iptogler
                                listdataapi={listdataapi}
                                btst={
                                  row && row?.ip && row?.ip?.isBlocked ? row?.ip?.isBlocked : false
                                }
                                setLd={setLd}
                                Ipid={row && row?.ip && row?.ip?.id ? row?.ip?.id : ''}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <tr className={'text-center'}>
                          <td colSpan={5} align={'center'}>
                            No Record(s) Found.
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
              <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
            </Typography>
          </Typography>
        </Grid>
        <ConfirmDialog
          title='Confirm'
          floatingBtnShow={true}
          open={confirmOpen.show}
          close={() =>
            setConfirmOpen({
              show: false,
              id: '',
            })
          }
          visibleClose={true}
          onConfirm={(act: any) =>
            act === 'yes'
              ? deletebtn(confirmOpen.id)
              : setConfirmOpen({
                  show: false,
                  id: '',
                })
          }
          autoCloseDisable={true}>
          {'Are you sure do you want to delete?'}
        </ConfirmDialog>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default DailyLoginDetails;
